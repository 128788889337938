<template>
    <aside class="doughnut">
        <div class="chart-box" v-if="is_show_cart">
            <div ref="chart" class="chart"></div>
        </div>
        <NoData v-else :image="require('@/assets/images/pen.png')" text="" title="No data available" />

    </aside>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import * as echarts from 'echarts';
import NoData from '@/components/creator/NoData.vue';

export default {
    name: 'Doughnut',

    components: {
        SvgIcon,
        NoData,
        echarts,
    },

    data() {
        return {
            chart: null,
            chart_type: 'view',
            option: {},
            is_show_cart: true,
            colorList: [
                { name: 'android', color: '#3DDC84' },
                { name: 'search', color: '#3DDC84' },
                { name: 'ios', color: '#8E8E93' },
                { name: 'tiktok', color: '#010101' },
                { name: 'instagram', color: '#C32AA3' },
                { name: 'others', color: '#FAA378' },
                { name: 'other', color: '#FAA378' },
                { name: 'windows', color: '#0078D4' },
                { name: 'motom', color: '#232A60' },
                { name: 'youtube', color: '#FF0000' },
                { name: 'linktree', color: '#d1e823' },
                { name: 'beacons', color: '#007BFF' },
                { name: 'Women', color: '#1DB954' },
                { name: 'Men', color: '#1DA1F2' },
                { name: 'Other', color: '#8E8E93' },
                { name: 'Landing page', color: '#8E8E93' },
                { name: 'landing page', color: '#8E8E93' },
                { name: 'a', color: '#8E8E93' },
                { name: 'facebook', color: '#0866FF' },
                { name: 'f', color: '#0866FF' },
            ]
        }
    },

    props: {
        color: {
            type: Array,
            default: []
        },
    },

    methods: {
        resizeChart() {
            this.$nextTick(() => {
                if (this.$refs.chart) {

                    let myChart = echarts.init(this.$refs.chart);
                    myChart.setOption(this.option);
                    myChart.resize();
                }
            })
        },

        update(data) {
            let type = data.type;
            let obj = data.data;
            let i = 0;
            if (obj) {
                obj.forEach((o, key) => {
                    i += o.value;
                });


                //OTHERS TURN LAST
                let othersIndex = obj.findIndex(e => { return e.name == 'others' });

                if (othersIndex !== -1) {
                    let othersObj = obj[othersIndex];
                    obj = obj.filter(e => { return e.name !== 'others' });
                    obj.push(othersObj);
                }


                // const makeUpperCase = (str) => {
                //     let newStr = str.toUpperCase();
                //     return newStr.replace(/^./, newStr[0].toLowerCase());
                // }

                //fixed color
                obj = obj.map(e => {
                    // console.log(e);
                    let colorIndex = this.colorList.findIndex(ee => { return ee.name == e.name })
                    let name = e.name.replace(/^./, e.name[0].toUpperCase());
                    console.log('e.name',e.name)
                    switch (e.name) {
                        case 'ios':
                            name = 'iOS';
                            break;
                        case 'others':
                            name = 'Other';
                            break;
                        case 'tiktok':
                            name = 'TikTok';
                            break;
                        case 'a':
                            name = 'Landing page';
                            break;
                        case 'f':
                            name = 'Facebook';
                            break;
                    }


                    return {
                        ...e,
                        name: name,
                        itemStyle: {
                            color: colorIndex !== -1 ? this.colorList[colorIndex].color : ''
                        }
                    }
                })


                this.is_show_cart = (i !== 0);

                this.option = {
                    backgroundColor: '#FFFFFF',
                    color: this.color,
                    tooltip: {
                        trigger: 'item',
                        formatter: function (params, ticket, callback) {
                            if (type === 'sales_value') {
                                return `<div class="poe-tooltip">${params.marker} <strong>${params.data.name}</strong><span>${'$' + Number(params.data.value).toFixed(2)}</span></div>`;
                            } 
                            else if(type==='gender_value'){
                                return `<div class="poe-tooltip">${params.marker} <strong>${params.data.name}</strong><span>${(params.data.value * 100).toFixed(2)}%</span></div>`;
                            }
                            else {
                                return `<div class="poe-tooltip">${params.marker} <strong>${params.data.name}</strong><span>${Number(params.data.value).toLocaleString('en-US') }</span></div>`;
                            }
                        }
                    },
                    legend: {
                        padding: 16,
                        backgroundColor: '#F2F2F2',
                        borderRadius: 8,
                        bottom: '0%',
                        left: 'center'
                    },
                    series: [
                        {
                            // name: 'Access From',
                            type: 'pie',
                            bottom: '20%',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                            },
                            emphasis: {
                                label: {
                                    show: false,
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: obj
                        }
                    ]
                };
            }

            if (!this.chart) this.chart = echarts.init(this.$refs.chart);
            this.chart.setOption(this.option);
        },
    },

    created() {
        window.addEventListener('resize', this.resizeChart);
    },

    destroyed() {
        window.removeEventListener('resize', this.resizeChart);
    },
}
</script>
<style lang="scss">
.doughnut {

    .title {
        font: var(--font-demi-16);
        color: var(--black-90);
    }

    .creator-no-data img {
        width: 120px;
    }

    .chart-box {
        // background: var(--bg-02);

        // .type-box {
        //     display: flex;
        //     cursor: pointer;

        //     .type {
        //         width: 25%;
        //         padding: 24px 16px;
        //         background: white;
        //         border: 1px solid rgba(0, 0, 0, 0.08);

        //         &.selected {
        //             background: var(--bg-02);
        //             border: unset;
        //         }

        //         .text {
        //             font-weight: 500;
        //             font-size: 12px;
        //             line-height: 14px;
        //             color: var(--black-75);
        //         }

        //         .value {
        //             font-weight: 500;
        //             font-size: 24px;
        //             line-height: 32px;
        //             color: var(--black-90);
        //             display: flex;

        //             svg {
        //                 margin-left: 10px;
        //                 width: 10px;
        //                 height: 10px;
        //                 fill: var(--green);
        //             }
        //         }
        //     }
        // }

        .chart {
            width: auto;
            height: 300px;
            // padding-bottom: 60px;
        }

        .poe-tooltip {
            strong {
                font-weight: bold;
                padding-right: 12px;
            }
        }
    }

}
</style>