<template>
    <div class="dropdown-hashtag-all" ref="el" :class="{'show': is_show}" @click.stop="handleClick" :style="drop_style"> 
        <div :class="['small-label', tags.length === 0 && 'label-center']">
            <h3>All tags</h3>
            <svg-icon name="close" @click.stop="close()"/>
        </div>
        <!-- <Scrollbar> -->
        <Loading v-if="$store.state.is_loading" class="static" />
        
        <div v-else class="tags-list-box-all" >
            <div @click.stop="$emit('open-cre-tag')" class="add-tag-btn" v-if="!$store.state.merchant_disabeld"><svg-icon name="tag-plus" /></div>
            <Badage class="tag-item-box" v-for="(v,k) in tags" :key="k" :value="v.text" :show_tippy="true">
                <svg-icon class="ths-show" name="close" @click.stop="handleSelectTag({tag:v, row:row },'from_outside')" v-if="!$store.state.merchant_disabeld"/>
            </Badage >
        </div>
        <!-- </Scrollbar> -->
    </div>

    <Modal :is_show="tag_remove_modal" class="alert-modal alert-modal-indside" @click-bg="tag_remove_modal = false">
        <div class="box remove-box">
            <header class="delete-header" >Remove tag?</header>
            <p style="word-break: break-all;">Are you sure you want to remove <br/>{{ handle_remove_tag_value.tag.text }}?</p>
            <div class="btns">
                <Button type="button" color="no-color" size="32" @click.stop="tag_remove_modal = false">No</Button>
                <Button type="button" color="dark-blue" size="32" @click.stop="tag_remove_modal = false;selectTag( handle_remove_tag_value ,'from_outside')">Yes, confirm</Button>
            </div>
        </div>
    </Modal>

</template>
<script>
import Loading from '@/components/ui/Loading.vue';
import Button from './ui/Button.vue';
import Modal from './ui/Modal.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import Badage from '@/components/ui/Badage.vue';
import { selectTag } from '@/utils/CreatorTagFun';

export default {
    name: 'DropdownHashtag',
    emits: ['open-cre-tag','closeKey'],

    components: {
        Loading,
        Button,
        Modal,
        Scrollbar,
        Badage,
    },

    data() {
        return {
            is_show: false,
            is_timer: false,
            tag_remove_modal:false,
            handle_remove_tag_value:{//抓住準備要unbind的tag和creator
                row:null,
                tag:''
            },
        }
    },

    props: {
        tags:{
            type: Array,
            default: () => [],
        },

        open_key:{ //針對每一個創作者的開啟方式
            type: Boolean,
            default: false,
        },

        row:{
            type: [Object, Array],
            default: () => [],
        },

        drop_css:{
            type: String,
            default: '',
        },

        index:{
            type: Number,
            default: null,
        },

    },

    watch:{
        open_key(val){
            if(val){
                this.open();
            }else{
                this.close()
            }
        },
        

    },

    computed: {
        drop_style(){
            if(this.index!==null){
                if(this.index>6){
                    if(this.index===9999){
                        return `${this.drop_css? this.drop_css:'left: 160px'}`;
                    }else{
                        return `bottom:0px; ${this.drop_css? this.drop_css:'left: 200px'}; top:unset;`;
                    }
                
                }else{
                    return `top:0px; ${this.drop_css? this.drop_css:'left: 225px'};`;
                }
                
            }else{
                return '';
            }
        },

    },

    methods: {
        handleClick(e) {
            if (this.$refs.el && !this.$refs.el.contains(e.target)) {
                this.close();
            }
        },

        open() {
            this.is_show = true;
            this.is_timer = true;

            setTimeout(() => {
                this.is_timer = false;
            }, 100);
        },

        close() {
            this.is_show = false;
            if(this.open_key){
                this.$emit('closeKey')
            }
        },

        //這邊會處理單人的tag刪減增加,API處理會在元件裡面做完(除了有spec_text的要另外CALL)
        selectTag(item,spec_text){
            selectTag( item, spec_text, this  ); 
        },

        handleSelectTag(item,spec_text){
            this.handle_remove_tag_value.row=item.row;
            this.handle_remove_tag_value.tag=item.tag;
            this.tag_remove_modal=true;
        },

    },

    created() {
        window.addEventListener('click', this.handleClick);
    },

    destroyed() {
        window.removeEventListener('click', this.handleClick);
    },
}
</script>
<style lang="scss">
.dropdown-hashtag-all {
    position: absolute;
    background-color: white;
    top: 100%;
    right: unset;//-20px;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    z-index: 9;
    width: 400px;
    display: none;

    &.show {
        display: block;
    }

    .small-label {
        padding: 12px;
        border-bottom: 1px solid var(--black-20);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.label-center{
            border:none;
        }

        h3 {
            font-size: 12px;
            font-weight: 500;
            color: var(--black-75);
        }

        .svg-icon{
            width: 12px;
            height:12px;
            cursor:pointer;
        }
    }

    .tags-list-box-all{
        display:flex;
        align-items: center;
        flex-wrap: wrap;
        padding:4px;
        gap:6px;
        transition: .3s;
        border:solid 1px var(--white);

        .my-badges-iden{
            display:flex;
            align-items: center;
            border-radius: 6px;
            max-height: 24px;

            span{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 120px;
            }

            &:first-child{
                span{
                    max-width: 150px;
                }
            }
        }
        

        .svg-icon{
            margin-left: 6px;
            width: 0px;
            transition: opacity 0.3s ease;
            cursor: pointer;

            &.ths-show{
                width: 8px !important;
            }
        }

        &.edit-tags-list-box{
            border:solid 1px var(--neutral);
            border-radius:6px;
            
        }
        
    }

    .add-tag-btn{
        padding: 4px;
        border:solid 1px var(--neutral);
        border-radius:6px;
        max-height: 24px;

        .svg-icon{
            margin: unset;
            width: 14px;
            height: 14px;
        }
    }
}

.alert-modal-indside{
    .ui-modal--container {
        padding:0 !important;
    }
}
</style>