

<template>
    <div class="report-item-2" v-if="data">
        <div class="info">
            <div class="text">
                <SvgIcon name="top-rank-flag-2" />{{ data.title }}
            </div>
        </div>
        <div :class="['top',data.user && 'top-hover']" @click.stop="goProfile(data.user)">
            <figure class="avatar">
                <img v-if="data.user" class="had-img" :src="data.user.userProfile.avatar_full_url"
                    @error="imageError($event)" />
                <img v-else src="@/assets/images/user.png" />
            </figure>
            <div class="value-box">
                <div class="value">
                    <SvgIcon :name="data.icon" />{{ show_text }}
                </div>
                <div :class="['name',data.user && 'name-hover']">{{ (data.user) ? data.user.first_name + ' ' +
                    data.user.last_name : '--' }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';

export default {
    name: 'TopBox',

    components: {
        SvgIcon
    },

    data() {
        return {
            show_text: '--',
        }
    },

    props:{
        data:{
            type:Object
        }
    },

    methods:{
        imageError(event) {
            event.target.src = require('@/assets/images/user.png');
        },

        goProfile(data){
            console.log(data)
            if(data){
                data.id=data.anchor_creator_id;
                data.user_id=data.anchor_creator_id;
                
                if(this.$store.state.creator_data_open){
                    this.EventBus.emit('update-modal-creator',data)
                }else{
                    setTimeout(() => {
                        this.EventBus.emit('open-creator-modal',data);
                    },10);
                }
                this.$emit('close')     
            }
        }
    },

    mounted() {
        if (this.data.user && this.data.user.total_count !== 0) {
            if (this.data.icon === 'shopping-cart') {
                this.show_text = '$' + Number(this.data.user.total_count).toFixed(2);
            } else {
                this.show_text = this.data.user.total_count;
            }
        }

    },
}
</script>
<style lang="scss">
.report-item-2{
    .top-hover {
        cursor: pointer;
        &:hover{
            background-color: var(--bg-08);
        }
        
    }

}
</style>