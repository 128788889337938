import { createStore } from 'vuex';

export default createStore({
    state: {
        user_data: false,
        //$store.state.user_data.is_motom_internal_user(內部人員才看的到的值)
        merchant_disabeld:false,
        disabled_scroll: false,
        upload_data: false,
        is_show_share: false,
        import_count: 0,
        department_select: [],
        role_select: [],    
        // role_id === 1 => admin 
        // role_id === 2 => manager 
        // role_id === 3 => analyst
        role_id: null,
        current_creator_count: 0,
        creator_max_count: 0,
        current_portal_user_count: 0,
        portal_user_max_count: 0,
        is_loading: false,
        mail_to:'anchor.support@motom.me',
        pick_creator_user_id:'',
        creator_data:null,
        creator_data_open:false,
        brand_posts_key:0,
        program_data:{
            form_ForCreatorList:[],
            create_data:null,
            is_preview:false,
        },
        product_num:0,
        merchant_name:'',
        save_list_date_text:'',
        save_date_text:'',
        product_data_open:false,
        //特殊欄位
        spec_value:{
            del_tag_modal:false,//拿來監聽刪除的modal打開，list有顆按鈕會因為她打開要暫時調整CSS
            open_tag_remove_outside_modal:false,
            open_tag_edit_outside_modal:false,
            del_tag_num:0,
            list_filter_open:false,//紀錄list的filter打開
            list_tag_open:false,//紀錄list的tag打開
            list_status_open:false,//紀錄list的status打開
        },
        save_sortlink_data:true,
        sortlink_desc:`Includes data from affiliate shortlinks shared by the creator that go directly to the product page.`,
    },
    // getters: {
    // },
    mutations: {
        async check_limit(state, self) {
            state.is_loading = true;
            const res = await self.$ajax.get(`/settings/merchant-account-current-use-rate?t=${new Date().getTime()}`);
            const res_data = res.data.data;
            state.creator_max_count = res_data.creator_max_count;
            state.current_creator_count = res_data.current_creator_count;
            state.portal_user_max_count = res_data.portal_user_max_count;
            state.current_portal_user_count = res_data.current_portal_user_count;
            state.is_loading = false;
           
        },

    },
    // actions: {
    // },
    // modules: {
    // }
})
