<template>
    <Loading v-if="is_loading_me"/>
    <router-view v-else/>
    <ProductModalSide />
    <CreatorModalSide />
    <CollectionProductSide />
    <PostModalSide />
    <Share />
    <notifications class="center-center" group="center" position="center center" />
    <notifications class="center-alert" group="alert" position="center top" />
    <notifications class="center-white" group="white" position="bottom right" />
    <notifications class="bottom-right" position="bottom right" />
</template>
<script>
import '@/assets/svg/alert.svg?sprite';
import '@/assets/svg/yes.svg?sprite';
import Share from '@/components/ui/Share.vue';
import ProductModalSide from '@/components/product/ProductModalSide.vue';
import CreatorModalSide from '@/components/creator/CreatorModalSide.vue';
import CollectionProductSide from '@/components/creator/CollectionProduct.vue';
import PostModalSide from '@/components/creator/PostModalSide.vue';
import Loading from '@/components/ui/Loading.vue';
// import moment from 'moment';

export default {
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title;
                this.updateClass(to.params);
                if (this.$store.state.user_data) {
                    this.callUserStat();
                }
            },
        },
    },

    components: {
        Share,
        ProductModalSide,
        CreatorModalSide,
        CollectionProductSide,
        PostModalSide,
        Loading,
    },

    data(){
        return{
            is_loading_me:true,
        }
    },

    methods: {
        updateClass(params) {
            let body_class = '';
            if (this.$route.name !== 'home') body_class = 'not-home ';
            if (typeof this.$route.name === 'string') body_class = body_class + 'page-' + this.$route.name;
            document.body.className = body_class;
            if (this.$store.state.disabled_scroll) {
                this.$store.state.disabled_scroll = false;
            } else {
                window.scrollTo(0, 0);
            }

            if (this.$store.state.user_data.is_first_login === 1) this.$router.replace('/onboard');
        },

        getQueryParams(name) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(name);
        },

        selectFirstMerchant(id) {
            this.is_loading = true;
            this.$ajax(`/select-current-merchant-account`, {
                method: 'post',
                data: {
                    merchant_account_id: id
                },
            }).then(res => {
                // this.$router.replace('/creator/get-started');
                this.is_loading = false;
            });
        },

        getDepartment() {
            if (this.$store.state.department_select.length < 1) {
                this.$ajax.get(`/settings/department-list`).then(res => {
                    this.$store.state.department_select = res.data.data;
                    this.$store.state.department_select.forEach(item => {
                        item.text = item.name.charAt(0).toUpperCase() + item.name.slice(1);
                    });
                })
            }
        },

        getListRole() {
            if (this.$store.state.role_select.length < 1) {
                this.$ajax.get(`/settings/role-list`).then(res => {
                    res.data.data.forEach(e => {
                        this.$store.state.role_select.push({
                            ...e,
                            text: e.name.charAt(0).toUpperCase() + e.name.slice(1)
                        })
                    })
                });
            }
        },

        callUserStat() {
            this.$ajax(`/get-login-user-latest-status?t=${new Date().getTime()}`, {
                method: 'get',
            }).then(res => {
                // console.log('res.data.data.status', res.data.data.status)
                if (res.data.data.status !== 100) {
                    this.$router.replace('/logout');
                }
            })
        },
    },

    async mounted() {
        await this.$nextTick();
        this.is_loading_me=true;
        if (window.localStorage.getItem('access_token') !== null) {
            this.$ajax(`/me?t=${new Date().getTime()}`, {
                method: 'post',
            }).then(res => {
                res.data.data.creator_url = '/creator/list';
                // res.data.data.merchant_account = res.data.data.merchant_accounts[0];
                res.data.data.merchant_account = null;

                this.EventBus.emit('init-user-data', res.data.data);

                if (res.data.data.status === 0) {
                    this.$router.replace('/logout');
                    return
                }

                if (res.data.data.merchant_account_id) {
                    res.data.data.merchant_accounts.forEach(account => {
                        if (account.id === res.data.data.merchant_account_id) {
                            res.data.data.merchant_account = account;
                        }
                    });
                }

                if (res.data.data.is_first_login === 1 && this.$route.name !== 'onboard') {
                    this.$router.replace('/onboard');
                }

                if (res.data.data.merchant_account && res.data.data.merchant_account.enable_creators_count === 0) {
                    res.data.data.creator_url = '/creator/get-started';
                }

                // res.data.data.is_motom_internal_user = false;
                
                // Hack for DO not use dates that are too early P42-8968
                // const old_date = moment('1980-01-01');
                // if (res.data.data.contract_end_time) {
                //     const contract_end_time = moment(res.data.data.contract_end_time);
                //     if (contract_end_time.isBefore(old_date)) {
                //         res.data.data.contract_end_time = null;
                //     }
                // }

                // if (res.data.data.contract_start_time) {
                //     const contract_start_time = moment(res.data.data.contract_start_time);
                //     if (contract_start_time.isBefore(old_date)) {
                //         res.data.data.contract_start_time = null;
                //     }
                // }

                this.$store.state.user_data = res.data.data;
                this.$store.state.save_sortlink_data= res.data.data.is_include_shortlink_data;
                this.is_loading_me=false;
                

                if (this.$store.state.user_data.merchant_accounts.length < 1) {
                    this.$router.replace('/landing');
                }

                if (this.$route.name === 'programs--application' && !this.$store.state.user_data.merchant_account.is_enable_ambassador_program) {
                    window.location.replace('/programs/applications-list');
                }
                
                if (this.$store.state.user_data.merchant_accounts.length === 1) {
                    this.$store.state.user_data.merchant_account = this.$store.state.user_data.merchant_accounts[0];
                    this.selectFirstMerchant(this.$store.state.user_data.merchant_accounts[0].id);
                }

                else if (res.data.data.merchant_accounts.length > 1 && !res.data.data.merchant_account && this.$route.name !== 'settings') {
                    this.$router.replace('/landing');
                }

                else if (this.$store.state.user_data.is_first_login === 0 && this.$route.name === 'onboard') {
                    this.$router.replace('/');
                }

                if (res.data.data.roles[0]) {
                    if (res.data.data.roles[0].name == 'admin') {
                        this.$store.state.role_id = 1;
                    } else if (res.data.data.roles[0].name == 'manager') {
                        this.$store.state.role_id = 2;
                    } else if (res.data.data.roles[0].name == 'analyst') {
                        this.$store.state.role_id = 3;
                    } else {
                        this.$store.state.role_id = null;
                    }
                }

                this.getDepartment();
                if (this.$store.state.role_id == 1) {
                    this.getListRole();
                } else {
                    this.$store.state.role_select = [];
                }

                if (this.$store.state.user_data && this.$store.state.user_data.merchant_account) this.$store.commit('check_limit', this);
            });

        } else {
            this.is_loading_me=false;
            await this.$router.isReady();
            const ignore_pages = ['forgot-password', 'reset-password'];
            // console.log(this.$route.name)
            if (!ignore_pages.includes(this.$route.name)) {
                this.$router.replace('/login');
            }
        }

    },

    created() {
        const merchant_account_id = this.getQueryParams('merchant_account_id');
        if (merchant_account_id) {
            window.localStorage.setItem('merchant_account_id', merchant_account_id);
        }
        // this.$store.commit('check_limit', this);
    },


}
</script>


<style lang="scss">
@import '@/assets/sass/_fonts.scss';
@import '@/assets/sass/_reset.scss';
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html {
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    min-height: 100vh;
    background-color: var(--dark-blue);
}

body {
    font-family: var(--font);
    line-height: 1.42857;

    /* Font smoothing */
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    font-weight: normal;
    background-color: var(--dark-blue);
    min-height: 100%;

    &.disabled-scroll {
        overflow: hidden !important;
    }
}

* {
    box-sizing: border-box;
}

:root {
    --font: 'Franklin Gothic Book', sans-serif;
    --font-fg: 'Franklin Gothic', sans-serif;
    --font-demi: 'Franklin Gothic Demi', sans-serif;
    --font-med: 'Franklin Gothic Medium', sans-serif;
    --font-ms: 'Montserrat', sans-serif;
    --font-dm: 'DM Serif Display', sans-serif;

    --font-fg-10: 500 10px/14px var(--font-fg);
    --font-fg-12: 500 12px/16px var(--font-fg);
    --font-fg-14: 500 14px/18px var(--font-fg);
    --font-fg-16: 500 16px/20px var(--font-fg);
    --font-fg-18: 500 18px/22px var(--font-fg);
    --font-fg-20: 500 20px/24px var(--font-fg);
    --font-fg-22: 500 22px/26px var(--font-fg);
    --font-fg-24: 500 24px/28px var(--font-fg);
    --font-fg-26: 500 26px/30px var(--font-fg);
    --font-fg-28: 500 28px/32px var(--font-fg);
    --font-fg-30: 500 30px/34px var(--font-fg);
    --font-fg-32: 500 32px/36px var(--font-fg);

    --font-demi-10: 500 10px/14px var(--font-demi);
    --font-demi-12: 500 12px/16px var(--font-demi);
    --font-demi-14: 500 14px/18px var(--font-demi);
    --font-demi-16: 500 16px/20px var(--font-demi);
    --font-demi-18: 500 18px/22px var(--font-demi);
    --font-demi-20: 500 20px/24px var(--font-demi);
    --font-demi-22: 500 22px/26px var(--font-demi);
    --font-demi-24: 500 24px/28px var(--font-demi);
    --font-demi-26: 500 26px/30px var(--font-demi);
    --font-demi-28: 500 28px/32px var(--font-demi);
    --font-demi-30: 500 30px/34px var(--font-demi);
    --font-demi-32: 500 32px/36px var(--font-demi);
    --font-demi-34: 500 34px/38px var(--font-demi);
    --font-demi-36: 500 36px/40px var(--font-demi);
    --font-demi-38: 500 38px/42px var(--font-demi);
    --font-demi-40: 500 40px/44px var(--font-demi);

    --font-med-10: 500 10px/14px var(--font-med);
    --font-med-12: 500 12px/16px var(--font-med);
    --font-med-14: 500 14px/18px var(--font-med);
    --font-med-16: 500 16px/20px var(--font-med);
    --font-med-18: 500 18px/22px var(--font-med);
    --font-med-20: 500 20px/24px var(--font-med);
    --font-med-22: 500 22px/26px var(--font-med);
    --font-med-24: 500 24px/28px var(--font-med);
    --font-med-26: 500 26px/30px var(--font-med);
    --font-med-28: 500 28px/32px var(--font-med);
    --font-med-30: 500 30px/34px var(--font-med);
    --font-med-32: 500 32px/36px var(--font-med);

    --sidebar-width: 310px;
    --collapsed-sidebar-width: 74px;

    --white: #fff;
    --red: #ED1C24;
    --dark-red: #D83232;
    --alert: #F22028;
    --yellow: #efb008;
    --blue: #007BFF;
    --dark-blue: #232A60;
    --gray-blue: #94A3B8;
    --dark-gray-blue: #475569;
    --green: #00A870;
    --pink: #FE7A82;
    --orange: #F15A29;
    --neureal: #0F1A2A;
    --light-neutral: #F1F4F9;
    --light-yellow: #FFF5D5;
    --border: #E2E8F0;
    --light-blue: #D8F4FF;
    --light-green: #E8FCF1;
    --neutral:#CBD4E1;
    --neutral-60: #64748B;
    --neutral-70: #475569;
    --neutral-90: #1E2A3B;

    --text-primary: #333333;
    --text-secondary: #58595b;

    --bg-01: #fff;
    --bg-02: #F6F8FC;
    --bg-03: #f2f2f2;
    --bg-04: #FEFAF6;
    --bg-05: #FDFAF8;
    --bg-06: #FEF8F2;
    --bg-07: #FBCEBF;
    --bg-08: #F6F0E7;
    --bg-09: #FAA378;
    --bg-10: #FAFAFA;
    --bg-11: #FFEBEB;

    --black-100: #000000;
    --black-90: #333333;
    --black-75: #58595B;
    --black-60: #666666;
    --black-50: #828282;
    --black-40: #BDBDBD;
    --black-20: #E0E0E0;
    --black-10: #F2F2F2;

    --border:#E5E5E5;

    // shadow https://www.figma.com/file/OZYT5l0h3pSMxgtBh722ff/%F0%9F%A7%A7-%E3%80%90v-2.0.0%E3%80%91-Design-Library---Basic?node-id=891%3A10101
    --bot-1dp: 0px 1px 2px 0px rgba(0, 0, 0, .1);
    --bot-2dp: 0px 2px 4px 0px rgba(0, 0, 0, .1);
    --bot-4dp: 0px 4px 8px 0px rgba(0, 0, 0, .1);
    --bot-6dp: 0px 6px 8px 0px rgba(0, 0, 0, .1);

    --top-1dp: 0px -1px 2px 0px rgba(0, 0, 0, .1);
    --top-2dp: 0px -2px 4px 0px rgba(0, 0, 0, .1);
    --top-4dp: 0px -4px 8px 0px rgba(0, 0, 0, .1);
    --top-6dp: 0px -6px 8px 0px rgba(0, 0, 0, .1);

    --right-1dp: 1px 0px 2px 0px rgba(0, 0, 0, .1);
    --right-2dp: 2px 0px 4px 0px rgba(0, 0, 0, .1);
    --right-4dp: 4px 0px 8px 0px rgba(0, 0, 0, .1);
    --right-6dp: 6px 0px 8px 0px rgba(0, 0, 0, .1);

    --left-1dp: -1px 0px 2px 0px rgba(0, 0, 0, .1);
    --left-2dp: -2px 0px 4px 0px rgba(0, 0, 0, .1);
    --left-4dp: -4px 0px 8px 0px rgba(0, 0, 0, .1);
    --left-6dp: -6px 0px 8px 0px rgba(0, 0, 0, .1);

    --soc-twit: #1DA1F2;
    --soc-fb: #1877F2;
    --soc-fb2001: #3B5998;
    --soc-yt: #FF0000;
    --soc-google: #0F9D58;
    --soc-instagram-color: $instagram;
    --soc-instagram: linear-gradient(215.8deg, #A844A1 18.29%, #AB429A 23.73%, #B43C88 31.48%, #C33269 40.67%, #D7243F 50.72%, #D7243E 50.95%, #F9A326 79.54%, #F9DD26 94.95%);
    --soc-snapchat: #FFFC00;
    --soc-pinterest2001: #BD081C;
    --soc-pinterest: #E60023;
    --soc-tiktok: #333333;
    --soc-apple: #000000;
}

@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.disabeld-box{
    cursor: default;
    pointer-events: none;
    .icon{
        opacity: .5;
    }
}
.disabeld-box .enabled-btn {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
}

.wrap {
    height: 100vh;
    display: flex;

    &>.content {
        flex: 1;
        background-color: white;
    }

    a {
        color: var(--blue);
        text-decoration: none;
        transition: .15s color ease;

        &:hover {
            color: var(--dark-blue);
        }
    }
}

.black-90-text {
    color: var(--black-90);
}

// .notifications-bottom-right {

// }


.vue-notification-group {
    z-index: 100000000 !important;

    &.bottom-right {
        right: 8px !important;
        bottom: 8px !important;
        width: 320px !important;
        top: auto !important;
        transform: unset !important;

    }

    &.center-center {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50% !important;
        width: auto !important;
        max-width: 320px;
    }

    &.center-white {
        right: 8px !important;
        bottom: 8px !important;
        width: 320px !important;
        max-width: 320px;
    }

    &.center-alert{
        transform: translate(-50%, 20px);
        top: 50%;
        left: 50% !important;
        width: auto !important;

        span{
            display: flex;
            align-items: center;

            a{
                cursor: pointer;
            }
        }
    }

    .vue-notification-wrapper {
        overflow: unset;
    }

    .vue-notification {
        font-size: 14px;
        padding: 16px;
        background: #A5E1BF;
        border: none !important;
        font-family: var(--font);
        line-height: 1.42857;
        color: var(--dark-blue);
        border-radius: 6px;
        box-shadow: 2px 4px 30px 1px rgba(0, 0, 0, 0.15);
    }

    .vue-notification:has(.err-notify) {
        background: var(--alert);
        color: var(--white);

        svg {
            fill: var(--white);
        }
    }

    .vue-notification.warning {
        background-color: var(--bg-11);

        a {
            display: inline-block;
            margin-left: 8px;
        }
    }

    .vue-notification.alert,
    .vue-notification.success {
        background-color: white;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
        color: var(--black-90);
        font-size: 14px;
        padding: 12px 16px;

        p {
            display: flex;
            align-items: center;
            flex: 1;
            min-width: unset !important;

            .text {
                flex: 1;
                padding-right: 10px;
                font: var(--font-med-14);
            }

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                height: 30px;
                border-radius: 6px;
                color: var(--dark-blue);
                cursor: pointer;
                transition: .15s background-color ease;
                font: var(--font-med-12);
                border: 1px solid var(--neutral-70);

                &:hover {
                    background-color: var(--light-neutral);
                }
            }


            svg {
                fill: var(--green);
                max-width: 14px;
                max-height: 14px;

                &.alert {
                    fill: var(--alert);
                }
            }

            .close {
                margin-left: 12px;
                cursor: pointer;
                
                svg {
                    fill: var(--black-50);
                    max-width: 12px;
                    max-height: 12px;
                }
            }
        }

    }

    .vue-notification.alert-red{
        background-color: var(--bg-11);
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    }

    .notification-content,
    .notification-title {
        display: flex;
        align-items: center;
        font: var(--font-fg-12);

        svg {
            height: 16px;
            width: 16px;
            margin-right: 8px;
            display: block;
            fill: var(--dark-blue);
        }
    }
}

.hashtag-modal {
    .tag-header {
        padding: 0 24px;
        text-align: center;
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid var(--black-20);
        font-size: 16px;
        color: var(--black-90);
        position: relative;

        i {
            position: absolute;
            right: 12px;
            top: 50%;
            margin-top: -16px;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                height: 14px;
                width: 14px;
                display: block;
                fill: var(--black-50);
            }

            &:hover svg {
                fill: var(--dark-blue);
            }
        }
    }

    &>.ui-modal--container {
        width: 720px;
    }

    .ui-hashtag {
        max-height: 80vh;
        overflow: auto;
        padding: 0 24px;
    }
}

.import-modal {

    &>.ui-modal--container {
        width: 375px;
    }

    .tag-header {
        align-items: center;

        .ico-title {
            width: 22px;
            height: 22px;
            margin-right: 8px;
        }
    }
}

.import-modal {

    &>.ui-modal--container {
        width: 375px;
    }

    .tag-header {
        display: flex;
        align-items: center;
        font: var(--font-demi-20);
        color: var(--black-90);

        .ico-title {
            width: 22px;
            height: 22px;
            margin-right: 10px;
        }
    }

    .tag-menus {
        display: flex;
        padding: 24px 16px 0;

        &+.ui-import {
            padding-top: 12px;
        }


        li {
            margin-right: 10px;

            .ui-button.disabled {
                cursor: pointer;
                pointer-events: auto;
                border-color: var(--border);
                color: var(--black-75);
            }
        }
    }
}

.add-creator-modal {
    .ui-modal--container {
        width: 960px;
    }

    header {
        padding: 0 24px;
        text-align: center;
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid var(--black-20);
        font-size: 16px;
        color: var(--black-90);
        position: relative;

        i {
            position: absolute;
            right: 12px;
            top: 50%;
            margin-top: -16px;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                height: 14px;
                width: 14px;
                display: block;
                fill: var(--black-50);
            }

            &:hover svg {
                fill: var(--dark-blue);
            }
        }
    }

    .box-wrap {
        padding: 24px;
        display: flex;
        align-items: flex-start;
        max-height: 80vh;
        flex: 1;

        .add-partner {
            padding: 0;
            flex: 1;

            .form-wrap {
                margin: 0;
                background-color: var(--bg-02);
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23232A60' stroke-width='2' stroke-dasharray='8%2c 12' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");

                // &.upload-form {
                //     margin-top: 24px;
                // }
            }
        }

        &>ul {
            width: 360px;
            margin-left: 24px;

            li {
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

//**************************************************** */
//for program editing
//**************************************************** */
.edit-program-set-pop {
    
    .ui-modal--container{
        height: auto;
        max-height: 80vh;
        width: 600px;
        overflow: hidden;
    }

    .edit_program_header {
        text-align: left;
        font: var(--font-med-20);
        padding: 8px 16px;
        height: unset;
    }

    .edit-scroll-list {
        margin: 16px;
        max-height: calc(80vh - 150px);
        margin-bottom: 16px;
        padding: 0 8px;
        overflow-x: hidden;
    }

    .save-btn-box{
        margin: 16px auto;
        padding: 0 16px;

        .save-btn{
            width: 100%;
            text-align: center;
        }
    }

    .edit_program_body {

        .box {
            margin-bottom: 16px;

            .desc {
                font: var(--font-med-12);
                color: var(--black-50);
                @include flexCenter();
            }

            .red {
                color: var(--alert);
            }

            .ui-dropdown{
                .ui-button{
                    justify-content: space-between;
                }

                ul.item-box{
                    width: 100%;
                    .scrollbar-content{
                        max-height: 40vh;
                        width: 100%;
                    }
                }
            }

            .edit-item {
                margin-top: 16px;
                color: var(--black-90);        

                .line{
                    @include flexCenter();
                    width: 100%;
                }

                .handle_req{
                    font: var(--font-med-12);
                    @include flexCenter();

                    .req-box{
                        @include flexCenter();
                        margin-right: 8px;
                        cursor: pointer;
                    }
                    .ui-checkbox{
                        margin:unset;
                        
                    }
                }

                .group{
                    width: 100%;
                    border-left: solid 2px var(--neutral-60);

                    .edit-item{
                        padding-left: 16px;

                        .ui-dropdown{
                            width: 150px;

                            ul.item-box{
                                .scrollbar-content{
                                    margin: unset;
                                    width: 150px;
                                    max-height: 20vh;
                                }
                            }
                        }
                    }
                }

                .name {
                    font: var(--font-med-16);
                    @include flexCenter();
                    flex: 1;
                }

                .color-gray {
                    .name {
                        color: var(--black-40);
                    }

                    &.disabeld-line {
                        opacity: 1;

                        .ui-switch {
                            opacity: .5;
                        }
                    }
                }

                &.tell-title{
                    margin-top: unset;

                    .line{
                        display: unset;
                    }
                    
                    input{
                        width: 100%;
                        margin-top: 12px;
                        border-color:var(--neutral);
                    }
                }
                
            }

            .desc-box{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
                margin-top: 16px;
                cursor: pointer;
            }

            .hr{
                border-bottom: solid 1px var(--gray-blue);
                height: 1px;
                flex: 1;
            }

            .svg-icon-edit{
                width: 12px;
                height: 12px;
                cursor: pointer;
                fill:var(--black-50);
                transition: .2s all ease;
                transform: rotate(-180deg);
                margin-right: 2px;

                &.less {
                    transform: rotate(0deg);
                }
            }
        }

        .btn {
            margin-top: 8px;
        }
    }
}

//**************************************************** */

#app {

    .tippy-box {
        background-color: var(--dark-gray-blue);
        border-radius: 6px;
        font-size: 12px;
        // padding: 0 !important;
    }

    .tippy-arrow {
        color: var(--dark-gray-blue);
    }

    // .tippy-box[data-theme~=light] {

    // }

}

span.flex {
    display: flex;
    align-items: center;
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
    opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.alert-modal {
    z-index: 9999 !important;

    .ui-modal--container {
        width: 330px;
    }


    .box {
        padding: 24px;

        .delete-header,
        header {
            font: var(--font-demi-20);
            color: var(--black-90);
            letter-spacing: 1px;
            margin-bottom: 8px;
        }

        p {
            font-size: 16px;
            color: var(--black-60);
            white-space: normal;
            line-height: 24px;
        }

        .btns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 32px 0 0 !important;


            .ui-button {
                min-width: 70px;
                text-align: center;
                margin-left: 10px;
                font-size: 14px;
            }
        }
    }

}

.overflow-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.sticker-tab-box {
    @include flexCenter();
    margin-top: 24px;
    margin-bottom: 16px;

    .ui-button {
        margin-right: 16px;
        border-radius: 6px !important;
        display: inline-flex;
        align-items: center;
        border: 1px solid white !important;
        // background-color: var(--black-10);
        line-height: 1;
        box-sizing: border-box;
        text-wrap: nowrap;

        &:hover {
            background-color: var(--bg-02);
            border-color: var(--bg-02) !important;
        }

        &.actived,
        &.actived:hover {
            border: 1px solid var(--dark-blue) !important;
            color: var(--dark-blue);
            background-color: white;
        }

        svg {
            margin-right: 6px;
        }
    }
}

.swiper {
    width: 100%;
}

.analytics-big-box {
    width: calc(100% - 320px);
    //min-width: 1200px;
}


.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .ui-modal--container,
.modal-leave-to .ui-modal--container {
    transform: scale(1.1);
}
</style>
