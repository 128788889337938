<template>
    <div :class="classnames" v-if="$store.state.merchant_disabeld">
        <svg-icon name="alert"/>
        <p>Your Anchor account is not currently active.
            <a :href="mailto" > Contact us to unlock your account.</a>
        </p>
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: 'my-alert',

    props: {
        value: {
            type: [String, Number],
            default: '',
        },

    },

    data() {
        return {

        }
    },

    computed: {
        classnames() {
            let arr = ['alert--on-top-box',``];
            return arr;
        },
        mailto(){
            return `mailto:anchor.support@motom.me?subject=${this.$store.state.user_data.merchant_account ? this.$store.state.user_data.merchant_account.merchant_display_name + ' - ' : ''}Support Request`
        }
    },

    methods: {

    },

    mounted() {
    },
}

</script>

<style lang="scss">
.alert--on-top-box {
    color: var(--text-secondary);
    background-color: var(--bg-11);
    padding: 12px;
    font:var(--font-med-12);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2), 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-height: 40px;

    svg{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }

    p{
        display: flex;
        align-items: center;
        justify-content: flex-start; 

        a{
            margin-left: 6px;
            text-decoration: underline;
        }
    }
}
</style>