<template>
    <div :class="['admin-content',($store.state.merchant_disabeld && !hide_alert) && 'admin-content-disabeld']"> 
        <AlertBox v-if="!hide_alert"/>
        <slot></slot>
    </div>
</template>

<script>
import AlertBox from '@/components/AlertBox.vue';
export default {
    name: 'Content',
    props:{
        hide_alert:{
            default:false,
            type:Boolean
        }
    },
    components:{
        AlertBox,
    }

};
</script>

<style lang="scss">
.admin-content {
    flex: 1;
    border-radius: 16px 0 0 0;
    background: white;
    margin-top: 16px;

    &.admin-content-disabeld{
        background-color: unset;
        margin-top: 0;
        .inner-box{
            border-radius: 16px 0 0 0;
            background: white;
            height: calc(100% - 40px) !important;
        }
    }


}
</style>